import { Injectable } from '@angular/core';
import {
    HttpResponse,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const accessToken = sessionStorage.getItem('accessToken');
        const token_type = 'Bearer';
        
        if (accessToken) {
            req = req.clone({
                headers: req.headers.set('Authorization', `${token_type} ${accessToken}`)
            });
           
        }

        return new Observable(observer => {
            const subscription = next.handle(req)
                .subscribe(
                    event => {
                        if (event instanceof HttpResponse) {
                            observer.next(event);
                        }
                    },
                    err => {
                        // // alert('error returned');
                        observer.error(err);
                    },
                    () => {
                        observer.complete();
                    });
            // Remove request from queue when cancelled
            return () => {
                subscription.unsubscribe();
            };
        });
    }
}