import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';



@Pipe({ name: 'localDateTime' })

export class LocalDateTimePipe implements PipeTransform {

    constructor() { }

    transform(value: string, format = 'MMMM Do YYYY, h:mm:ss a') {
        return moment.utc(value).local().format(format);
    }

}